/* eslint-disable */
import React, { useState } from 'react'
import { graphql } from 'gatsby'

import {
  VariableContent,
  useMapi,
  Typography,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'
import { Form } from '@leshen/ui'
const { EmilyClient } = require('@clearlink/emily')

import footer from '../components/Footer'
import header from '../components/Header'

const DoNotSell = ({ data }) => {
  const { requestId, sessionPromoCode } = useMapi()

  const brandyList = [
    'disclaimer-thirdpartymarketing',
    'disclaimer-plan',
    'disclaimer-evidence',
    'dsiclaimer-changenotice',
    'disclaimer-aetnabrand',
    'disclaimer-clicopyright',
    'disclaimer-incompletedescrip',
    'disclaimer-cmsmaterialid',
  ]

  const [showSubmissionMessage] = useState(false)

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data?.allBrandyDisclaimer?.edges),
        seo: {
          title:
            'Aetna 2023 Medicare Insurance | 855-200-5685 | Plans & Coverage',
          description:
            'Compare Aetna Medicare plans and let us help you enroll in the one that fits your healthcare needs. Call us today at 855-200-569',
          canonical: 'https://aetnamedicaredirect.com/do-not-sell',
          robots: 'follow,index',
        },
        path: '/do-not-sell',
        promoCode: '88487',
        ringpool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://aetnamedicaredirect.com/do-not-sell',
          siteName: 'aetnamedicaredirect.com',
          alternateName: 'aetnamedicaredirect.com',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate: 'aetnamedicaredirect.com | Do Not Sell',
    defaultRobots: 'follow,index',

    main: (
      <>
        <div className="wrapper">
          <VariableContent
            backgroundColor=""
            alignMainContent="center"
            mainContent={
              <>
                <Typography variant="h1">California Privacy Rights</Typography>
                <Typography variant="body">
                  The California Consumer Privacy Act (CCPA) gives California
                  residents certain rights regarding personal information given
                  to businesses. One of the rights granted is to direct a
                  business to stop selling your personal information. Along with
                  the information contained in our privacy policy, we want to
                  provide you a means to specifically state your preferences
                  with regard to the selling of your information. To submit a
                  request that your data not be sold, please fill out the form
                  on this page.
                </Typography>
              </>
            }
          >
            <Form
              formId={null}
              formSource="eloqua"
              submitButtonText="Submit"
              submitButtonColor="primary"
              submitButtonOutlined={false}
              submitButtonGradient={false}
              inlineButton={false}
              formName={null}
              onSubmit={async ({
                values,
                setShowSubmissionMessage,
                setShowSubmissionErrorMessage,
              }) => {
                const mergedValues = {
                  ...values,
                  brand: 'AET',
                  elqFormName: 'amd-do-not-sell',
                  origin: 'https://www.aetnamedicaredirect.com',
                  domain: 'https://www.aetnamedicaredirect.com',
                  promo_code: sessionPromoCode || 88487,
                  request_id: requestId,
                  source: 'amd-do-not-sell',
                }

                try {
                  const emilyClient = new EmilyClient(
                    'https://leads.clear.link'
                  )

                  const response = await emilyClient.submit(mergedValues)

                  if (response.status === 200) {
                    setShowSubmissionMessage(true) // Setting showSubmissionMessage to true here
                  } else {
                    setShowSubmissionMessage(false)
                  }
                  setShowSubmissionMessage(true)
                } catch (error) {
                  // If an error occurs:
                  console.error('Submission error:', error)
                  setShowSubmissionMessage(false)
                  setShowSubmissionErrorMessage(true)
                }
              }}
              emilyTCPAUpdate={() => {}}
              steps={null}
              showSubmissionMessage={showSubmissionMessage}
              fields={[
                {
                  name: 'first_name',
                  type: 'text',
                  label: 'First Name',
                  required: true,
                },
                {
                  name: 'last_name',
                  type: 'text',
                  label: 'Last Name',
                  required: true,
                },
                {
                  name: 'email_address',
                  type: 'email',
                  label: 'Email Address',
                  required: true,
                },
              ]}
              isMultiStep={false}
              heading={null}
              subheading={null}
              tcpaCheckbox={false}
              formDisclaimerPosition="Below Submit Button"
              disclaimer={null}
              initialStepValues={{}}
              smartyStreetsWebsiteKey={null}
              addressQualificationCode={null}
              spanish={false}
              requestId={null}
            />
          </VariableContent>
        </div>
      </>
    ),
    disableBreadcrumbs: false,
  }

  return <Layout {...layoutProps} />
}
export default DoNotSell

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
